import React from 'react';
import styled from 'styled-components';
import { withUnpublishedPreview } from 'gatsby-source-prismic';
import Page from '../templates/Page';
import Project from '../templates/Project';
import Layout from '../components/layout';
import HeroSection from '../components/HeroSection';

const NotFoundPage = () => (
  <Layout>
    <HeroSection header={`<h1>404</h1>`} subheader={`<p>Page Not Found</p>`}></HeroSection>
  </Layout>
);

export default withUnpublishedPreview(NotFoundPage, {
  templateMap: {
    page_template: Page,
    project_template: Project,
  },
});
